import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SigninService } from '../services/signin.service';

@Injectable({
  providedIn: 'root'
})
export class BranchGuard implements CanActivate {
  user: any;
  constructor(private router: Router, private _get: SigninService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.user = this._get.userDetails();
    if (this.user.typ === 'Admin' || this.user.typ === 'Support' || this.user.typ === 'Administrator') {
      // User is an Admin so return true
      return true;
    }
    // User is not an Admin so redirect to dashboard page
    alert('You do not have the privillege to access this module of the application');
    this.router.navigate(['/dashboard']);
    return false;
  }
}
