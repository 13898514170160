import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-location',
  templateUrl: './setup-location.component.html',
  styleUrls: ['./setup-location.component.css']
})
export class SetupLocationComponent implements OnInit {
  @ViewChild('locationForm') public any: NgForm;
  edit: boolean; list: boolean; loc: any; location: any[]; res: any;
  constructor(private _setup: SetupService, public _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllLocation();
  }

  getAllLocation() {
    this._setup.getLocations().subscribe((data: any) => {
      this.location = data;
      this._use.showMsg('success', 'Location List', 'Fetched Location list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Location List Fail', 'Unable to Fetch Location List'); });
  }

  editLoc(data) {
    // this.loc = this.location.find(s => s.locationid === id).
    this.loc = data;
    this.edit = true;
  }
  delLoc(dat) {
    this._setup.delLocation(dat.locationid).subscribe((data: any) => {
      this.res = data; this.location.slice(dat);
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postLocation() {
    this._setup.postLocation(this.loc).subscribe((data: any) => {
      this.res = data; this.location.push(data);
      this.formReset(this.any); this.list = false;
      this._use.showMsg('success', 'Location Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Location');
    });
  }

  putLocation() {
    this.loc.muserid = this._use.active.id; this.loc.mdate = new Date();
    this._setup.putLocation(this.loc.locationid, this.loc).subscribe((data: any) => {
      this.res = data; this.getAllLocation();
      this.formReset(this.any); this.list = false;
      this._use.showMsg('success', 'Location Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.loc = {
        locationid: 0, name: '', code: '',
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }
  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

}
