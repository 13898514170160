import { Injectable } from '@angular/core';
import { Router, Routes, RouterStateSnapshot } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpUserEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { ErrorsService } from '../services/errors.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    state: RouterStateSnapshot; url: any; cur: any;
    constructor(private router: Router, public toastr: ToastrService, private jwtHelper: JwtHelperService, private error: ErrorsService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.cur = req.url;
        this.url = this.cur.split('/').slice(-2)[0]; // console.log(this.url);
        if (this.url === 'upload') {
            req = req.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ` + this.jwtHelper.tokenGetter()
                }
            });
        } else {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ` + this.jwtHelper.tokenGetter()
                }
            });
        }
        this.error.request(req);
        return next.handle(req).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.toastr.error('Login Session Expired Please Login Again', 'UnAuthorize');
                localStorage.removeItem('fsp');
                location.reload(true);
                // this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
            }
            this.error.log(err.error);
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}

