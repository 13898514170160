import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { send } from 'q';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  @ViewChild('alertForm') @ViewChild('singleForm') public any: NgForm;
  send: any; sing: any; date: any; save: any; edit: boolean;
  cust: any[] = []; get: any; res: any;
  constructor(private _api: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.date = this._use.date;
    this.formReset();
    // this.getDefault();
  }

  getDefault() {
    this._api.getSmslogsDistinct().subscribe((data: any) => {
      this.cust = data;
      // this._use.showMsg('success', 'Sms Phone Numbers', 'Sms Status');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Get Mobile Numbers');
    });
  }

  postSingle() {
    this._api.getCode(this.sing.mobile).subscribe((data: any) => {
      this.sing = data;
      this._use.showMsg('success', 'Sms Sented', 'Sms Status' + data.response);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Sending Fail', 'Unable to Send Sms ' + err);
    });
  }

  postSms() {
    if (this.save.alertid > 0) {
      this._api.putAlert(this.save.alertid, this.save).subscribe((data: any) => {
        this.send = data; this.formReset();
        this._use.showMsg('success', 'Alert Updated', 'Alert Has been Updated Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Alert Fail', 'Unable to Update Sms Alert');
      });
    } else {
      this._api.postAlert(this.save).subscribe((data: any) => {
        this.send = data; this.formReset();
        this._use.showMsg('success', 'Alert Saved', 'Alert Has been Saved Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Alert Fail', 'Unable to Save Sms Alert');
      });
    }
  }

  getSms(value) {
    this._api.getAlertByType(value).subscribe((data: any) => {
      this.save = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'No Saved Aler message for ' + value);
    });
  }

  getAllC() {
    this.cust.forEach((e: any) => {
      if (e.mobile.length > 9) {
        this.send.mobile += e.mobile + ',';
        this.send.count += 1;
      }
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.send = { mobile: '', message: '', option: 'All', count: 0,
        userid: this._use.active.id, date: new Date() };
      this.sing = { tcode: '', mobile: '', message: '', code: '',
        userid: this._use.active.id, date: new Date() };
      this.save = {
        alertid: 0, type: '', message: '', name: false, auto: true,
        userid: this._use.active.id, date: new Date()
      };
    }
  }

}
