import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.sass']
})
export class ViewCustomerComponent implements OnInit {
  transactions: any[] = []; filtered: any[] = []; customer: any; st: any; id = 0; exp: any; upload:any[]=[];
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ManagerService, private route: Router, private router: ActivatedRoute, private use: NavComponent, public ext: ExportService) { }

  ngOnInit() {
    const code = this.router.snapshot.paramMap.get('code');
    // this.getAll(this.id);
    if (code != null) {
      this.getCustomer(code);
    }
  }

  getCustomer(code) {
    this.api.get('customer').subscribe((data: any) => {
      this.customer = data; this.filtered = data.transactions;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customer List');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.transactions.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  read(event) {
    // this.img = event.target.files[0];
    this.ext.readExcel(event.target.files[0]);
    // document.getElementById('upload').click();
  }

  remove(index) {
    this.ext.import.splice(index, 1);
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'customers');
      this.use.showMsg('success', 'Customers Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Customers Export Fail', 'There is no Item Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NAME: e.name, MOBILE: e.mobile, EMAIL: e.email, OTHER: e.other,
        ACTIVE: e.active, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
