import { Injectable } from '@angular/core';
import { config } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  private api = config.apiUrl; private apiurl = config.api;
  constructor(private http: HttpClient) { }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  getDashboard(): Observable<any> {
    return this.http.get(this.api + 'process/dashboard/');
  }

  getCode(type: string): Observable<any> {
    return this.http.get(this.api + 'sequence/code/' + type);
  }

  getAll(rout): Observable<any> {
    return this.http.get(this.apiurl + rout);
  }
  
  postcall(rout, data): Observable<any> {
    return this.http.post(this.apiurl + rout, data);
  }

  get(rout): Observable<any> {
    return this.http.get(this.api + rout);
  }

  post(rout, data): Observable<any> {
    return this.http.post(this.api + rout, JSON.stringify(data));
  }
  postcallback(apiurl, data): Observable<any> {
    return this.http.post(apiurl, JSON.stringify(data));
  }

  put(rout, data): Observable<any> {
    return this.http.put(this.api + rout, JSON.stringify(data));
  }

  delete(rout): Observable<any> {
    return this.http.delete(this.api + rout);
  }

  getItems(): Observable<any> {
    return this.http.get(this.api + 'item');
  }

  getItem(id: number): Observable<any> {
    return this.http.get(this.api + 'item/' + id);
  }

  getItemByCode(id): Observable<any> {
    return this.http.get(this.api + 'item/code/' + id);
  }

  postItem(data): Observable<any> {
    return this.http.post(this.api + 'item', JSON.stringify(data));
  }

  putItem(id: number, data): Observable<any> {
    return this.http.put(this.api + 'item/' + id, JSON.stringify(data));
  }

  delItem(id: number): Observable<any> {
    return this.http.delete(this.api + 'item/' + id);
  }

  getStocks(): Observable<any> {
    return this.http.get(this.api + 'stock');
  }

  getStock(id: number): Observable<any> {
    return this.http.get(this.api + 'stock/' + id);
  }

  getStockLog(id: number): Observable<any> {
    return this.http.get(this.api + 'stock/log/' + id);
  }

  getStockLogs(): Observable<any> {
    return this.http.get(this.api + 'stock/log');
  }

  postStock(data): Observable<any> {
    return this.http.post(this.api + 'stock', JSON.stringify(data));
  }

  putStock(id: number, data): Observable<any> {
    return this.http.put(this.api + 'stock/' + id, JSON.stringify(data));
  }

  delStock(id: number): Observable<any> {
    return this.http.delete(this.api + 'stock/' + id);
  }

  getPackages(): Observable<any> {
    return this.http.get(this.api + 'packages');
  }

  getPackage(id: number): Observable<any> {
    return this.http.get(this.api + 'packages/' + id);
  }

  postPackage(data): Observable<any> {
    return this.http.post(this.api + 'packages', JSON.stringify(data));
  }

  putPackage(id: number, data): Observable<any> {
    return this.http.put(this.api + 'packages/' + id, JSON.stringify(data));
  }

  delPackage(id: number): Observable<any> {
    return this.http.delete(this.api + 'packages/' + id);
  }

  getPayments(): Observable<any> {
    return this.http.get(this.api + 'payment');
  }

  getPayment(id: number): Observable<any> {
    return this.http.get(this.api + 'payment/' + id);
  }

  postPayment(data): Observable<any> {
    return this.http.post(this.api + 'payment', JSON.stringify(data));
  }

  putPayment(id: number, data): Observable<any> {
    return this.http.put(this.api + 'payment/' + id, JSON.stringify(data));
  }

  delPayment(id: number): Observable<any> {
    return this.http.delete(this.api + 'payment/' + id);
  }

  getOrders(): Observable<any> {
    return this.http.get(this.api + 'order');
  }

  getOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/' + id);
  }

  getOrderType(type): Observable<any> {
    return this.http.get(this.api + 'order/type/' + type);
  }

  getOrderList(id: number): Observable<any> {
    return this.http.get(this.api + 'order/list/' + id);
  }

  cancelOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/cancel/' + id);
  }

  acceptOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/accept/' + id);
  }

  readyOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/ready/' + id);
  }

  confirmOrder(id: number): Observable<any> {
    return this.http.get(this.api + 'order/confirm/' + id);
  }

  getOrderbyCode(id: string): Observable<any> {
    return this.http.get(this.api + 'order/code/' + id);
  }

  getOrderSummary(id: string, date: string): Observable<any> {
    return this.http.get(this.api + 'order/summary/' + id + '/' + date);
  }

  postOrder(data): Observable<any> {
    return this.http.post(this.api + 'order', JSON.stringify(data));
  }

  putOrder(id: number, data): Observable<any> {
    return this.http.put(this.api + 'order/' + id, JSON.stringify(data));
  }

  delOrder(id: number): Observable<any> {
    return this.http.delete(this.api + 'order/' + id);
  }

  getSessions(): Observable<any> {
    return this.http.get(this.api + 'session');
  }

  getSession(id: number): Observable<any> {
    return this.http.get(this.api + 'session/' + id);
  }

  postSession(data): Observable<any> {
    return this.http.post(this.api + 'session', JSON.stringify(data));
  }

  putSession(id: number, data): Observable<any> {
    return this.http.put(this.api + 'session/' + id, JSON.stringify(data));
  }

  closeSession(id: number, data): Observable<any> {
    return this.http.get(this.api + 'session/close/' + id + '/' + data);
  }

  delSession(id: number): Observable<any> {
    return this.http.delete(this.api + 'session/' + id);
  }

  getSubcribers(): Observable<any> {
    return this.http.get(this.api + 'subcribers');
  }

  getSubcriber(id: number): Observable<any> {
    return this.http.get(this.api + 'subcribers/' + id);
  }

  postSubcriber(data): Observable<any> {
    return this.http.post(this.api + 'subcribers', JSON.stringify(data));
  }

  putSubcriber(id: number, data): Observable<any> {
    return this.http.put(this.api + 'subcribers/' + id, JSON.stringify(data));
  }

  closeSubcriber(id: number, data): Observable<any> {
    return this.http.get(this.api + 'subcribers/close/' + id + '/' + data);
  }

  delSubcriber(id: number): Observable<any> {
    return this.http.delete(this.api + 'subcribers/' + id);
  }

  getAlerts(): Observable<any> {
    return this.http.get(this.api + 'alert');
  }

  getAlert(id: number): Observable<any> {
    return this.http.get(this.api + 'alert/' + id);
  }

  getAlertByType(id: string): Observable<any> {
    return this.http.get(this.api + 'alert/type/' + id);
  }

  postAlert(data): Observable<any> {
    return this.http.post(this.api + 'alert', JSON.stringify(data));
  }

  putAlert(id: number, data): Observable<any> {
    return this.http.put(this.api + 'alert/' + id, JSON.stringify(data));
  }

  delAlert(id: number): Observable<any> {
    return this.http.delete(this.api + 'alert/' + id);
  }

  getSettlements(): Observable<any> {
    return this.http.get(this.api + 'settlement');
  }

  getSettlement(id: number): Observable<any> {
    return this.http.get(this.api + 'settlement/' + id);
  }

  postSettlement(data): Observable<any> {
    return this.http.post(this.api + 'settlement', JSON.stringify(data));
  }

  putSettlement(id: number, data): Observable<any> {
    return this.http.put(this.api + 'settlement/' + id, JSON.stringify(data));
  }

  delSettlement(id: number): Observable<any> {
    return this.http.delete(this.api + 'settlement/' + id);
  }

  getUtilitys(): Observable<any> {
    return this.http.get(this.api + 'utility');
  }

  getUtility(id: number): Observable<any> {
    return this.http.get(this.api + 'utility/' + id);
  }

  postUtility(data): Observable<any> {
    return this.http.post(this.api + 'utility', JSON.stringify(data));
  }

  putUtility(id: number, data): Observable<any> {
    return this.http.put(this.api + 'utility/' + id, JSON.stringify(data));
  }

  delUtility(id: number): Observable<any> {
    return this.http.delete(this.api + 'utility/' + id);
  }
}
