import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SigninService } from 'src/app/services/signin.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  public active: any; res: any; public date: any; brn: any = { merchantid: '',smsbalance: 0, balance: 0, charge: 0, smscharge: 0 };
  constructor(private ser: SigninService, private set: SetupService, private _nav: Router, public toastr: ToastrService) { }

  ngOnInit() {
    this.init();
    this.active = this.ser.userDetails();
    this.date = JSON.parse(localStorage.getItem('Date'));
    console.log(this.active);
    this.getMerchant(this.active.merchantid);
    // this.getClaims();
  }

  getMerchant(val) {
    this.set.getCompany(val).subscribe((data: any) => {
      this.brn = data; console.log(this.brn);
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  showMsg(type, title, message) {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'warning') {
      this.toastr.warning(message, title);
    } else if (type === 'error') {
      this.toastr.error(message, title);
    }
  }

  getClaims() {
    this.ser.getClaim().subscribe((data: any) => {
      // console.log(data);
      // this.showMsg('success', 'Claims', JSON.stringify(data));
    }, (err: HttpErrorResponse) => { console.log(err); /*this._nav.navigate(['/login']);*/ });
  }

  logOut() {
    this.ser.logOut(this.active.unique_name).subscribe((data: any) => {
      this.res = data; localStorage.removeItem('Paynow'); // alert('Logout successfully');
      this.showMsg('success', 'Logout', 'You hav been Logout successfully @' + this.active.FullName);
      this._nav.navigate(['/login']);
    }, (err: HttpErrorResponse) => {console.log(err);
      localStorage.removeItem('Paynow');
      this._nav.navigate(['/login']);
    });
  }

  init() {
    this.active = { given_name: '', image: '', typ: '', };
  }

}
