import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { SigninService } from 'src/app/services/signin.service';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user: any; dash: any; cp: any; transactions: any[] = [];
  constructor(private ser: SigninService, private _api: SetupService, public toastr: ToastrService) { }

  ngOnInit() {
    this.init();
    this.user = this.ser.userDetails(); // console.log(this.user);
    this.formReset();
    this.Dashboard();
  }

  Dashboard() {
    // this.showMsg('success', 'Welcome', 'You Are Welcome @' + this.user.FullName);
    this._api.getDashboard().subscribe((data: any) => {
      this.dash = data;
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Error', 'Error fetching dashboard data');
    });
  }

  postPassword() {
    this.ser.changePassword(this.cp).subscribe((data: any) => {
      this.cp = data;
    }, (err: HttpErrorResponse) => {
      this.showMsg('error', 'Changing Password', 'Current Password is not correct');
    });
  }

  showMsg(type, title, message) {
    if (type === 'success') {
      this.toastr.success(message, title);
    } else if (type === 'warning') {
      this.toastr.warning(message, title);
    } else if (type === 'error') {
      this.toastr.error(message, title);
    }
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.dash = { sequence: 0, smsapi: 0, smslog: 0, alert: 0, receive: null, send: null, settlements: null, payment: null, failed: null };
      this.cp = {
        username: this.user.unique_name, oldpassword: '', newpassword: '', image: '',
        muserid: this.user.Id, mdate: new Date()
      };
    }
  }
  init() {
    this.user = { given_name: '', mobile: '', image: '', typ: '', email: '' };
  }

}
