import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-setup-session',
  templateUrl: './setup-session.component.html',
  styleUrls: ['./setup-session.component.css']
})
export class SetupSessionComponent implements OnInit {
  @ViewChild('sessionForm') public any: NgForm;
  ses: any; add: Boolean; edit: Boolean; curr: Date;
  session: any[] = []; branch: any[] = []; user: any;
  constructor(private _setup: ManagerService, private api: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.user = this._use.active;
    this.curr = new Date();
    this.formReset();
    this.getAllSession();
    this.getbranch();
  }

  getAllSession() {
    this._setup.getSessions().subscribe((data: any) => {
      this.session = data;
      this._use.showMsg('success', 'Session List', 'Fetched Session list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Session List Fail', 'Unable to Fetch Session List');
    });
  }
  getbranch() {
    this.api.getBranchs().subscribe((data: any) => {
      this.branch = data;
      this._use.showMsg('success', 'Session List', 'Fetched Session list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Session List Fail', 'Unable to Fetch Session List');
    });
  }

  close(id) {
    this._setup.closeSession(id, this._use.active.Id).subscribe((data: any) => {
      this.getAllSession();
      this._use.showMsg('success', 'Session Close', 'Session Has Been Closed Successfully');
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postSession() {
    this._setup.postSession(this.ses).subscribe((data: any) => {
      this.ses = data; this.session.push(data);
      this.formReset(); this.getAllSession();
      this._use.showMsg('success', 'Session Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Session');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.ses = {
        sessiondate: null, status: 'Active', branchid: this._use.active.branchid,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  back() {
    this.formReset();
  }

}
