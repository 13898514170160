import { Injectable, Injector } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/';
// import 'rxjs/add/observable/of';
// Cool library to deal with errors: https://www.stacktracejs.com
// import * as StackTraceParser from 'error-stack-parser';
import { SigninService } from './signin.service';
import { FirebaseService } from './firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  active: any; data: Observable<any[]>;
  constructor(private injector: Injector, private use: SigninService,
              private fb: AngularFireDatabase, private notify: NotificationService) {
      this.active = this.use.userDetails();
    }

  log(error) {
    // Log the error to the console
    // console.log(error);
    // this.notify.notify(error.message);
    // Send error to server
    const errorToSend = this.addContextInfo(error);
    // const errorToSend = JSON.stringify(error);
    // this.addErrorfb(errorToSend);
    // this._api.post('Errors', errorToSend);
  }

  logs(error) {
    // Log the error to the console
    // console.log(error);
    // this._notify.notify(error.message);
    // Send error to server
    const errorToSend = this.addContextInfo(error);
    // this.addErrorfb(errorToSend);
    // this._api.post('Errors', errorToSend);
  }

  request(reqs: HttpRequest<any>) {
    // Format error details
    const req = this.addContext(reqs);
    // const req = JSON.stringify(reqs);
    // console.log(req);
    // this.addRequestfb(req);
    // this._api.post('Requests', req);
  }

  addContextInfo(error) {
    // All the context details that you want (usually coming from other services; Constants, UserService...)
    const name = error.name || null;
    // const appId = this.active || 'troski';
    const user = this.active || 'troski-app';
    const time = new Date();
    const id = `${time.getTime()}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error;
    const errors = error;
    // const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
    const errorToSend = { id, name, user, time, url, status, message };
    return errorToSend;
  }

  addContext(msg: HttpRequest<any>) {
    const header = msg.headers || null;
    // const appId = this.active.id || 'troski';
    const user = this.active || 'troski-app';
    const time = new Date().toLocaleDateString();
    const id = `${new Date().getTime()}`;
    // const location = this.injector.get(LocationStrategy);
    const url = msg.url;
    const method = msg.method;
    const body = msg.body || null;
    const type = msg.responseType;
    const progress = msg.reportProgress;
    const request = msg;
    const data = { id, name, user, time, url, progress, method, type, body, request };
    return data;
  }

  addErrorfb(value) {
    this.fb.list('Errors').push(value);
  }

  addRequestfb(value) {
    this.fb.list('Requests').push(value);
  }

  // getError(): Observable<any[]> {
  //   return this._db.collection('/Errors').snapshotChanges();
  // }

}


