import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-setup-sms',
  templateUrl: './setup-sms.component.html',
  styleUrls: ['./setup-sms.component.css']
})
export class SetupSmsComponent implements OnInit {
  @ViewChild('smsapiForm') public any: NgForm;
  edit: boolean; list: boolean; api: any; smsapi: any[]; merchants: any[] = []; res: any;
  constructor(private _setup: SetupService, public _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllSmsApi();
    this.getMerchant();
  }

  getAllSmsApi() {
    this._setup.getSmsApis().subscribe((data: any) => {
      this.smsapi = data;
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }
  getMerchant() {
    this._setup.getCompanys().subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  editApi(data) {
    this.api = this.smsapi.find(s => s.smsapiid === data);
    // this.api = data;
    this.edit = true; this.list = true;
  }
  delApi(dat) {
    this._setup.delSmsApi(dat.smsapiid).subscribe((data: any) => {
      this.res = data; this.smsapi.slice(dat);
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postSmsApi() {
    this._setup.postSmsApi(this.api).subscribe((data: any) => {
      this.res = data; this.smsapi.push(data);
      this.formReset(); this.list = false;
      this._use.showMsg('success', 'SmsApi Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New SmsApi');
    });
  }

  putSmsApi() {
    this.api.muserid = this._use.active.id; this.api.mdate = new Date();
    this._setup.putSmsApi(this.api.smsapiid, this.api).subscribe((data: any) => {
      this.res = data; this.getAllSmsApi();
      this.formReset(); this.list = false;
      this._use.showMsg('success', 'SmsApi Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.api = { smsapiid: 0, merchantid: '', name: '', senderid: '', url: '', callackurl: '',
      username: '', password: '', default: false, status: 'Active',
      userid: this._use.active.id, date: new Date(), muserid: null, mdate: null };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

}
