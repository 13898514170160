import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, EmailValidator } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SigninService } from 'src/app/services/signin.service';

declare var $: any;

@Component({
  selector: 'app-setup-user',
  templateUrl: './setup-user.component.html',
  styleUrls: ['./setup-user.component.css']
})
export class SetupUserComponent implements OnInit {
  @ViewChild('userForm') public any: NgForm; sel: any;
  edit: boolean; list: boolean; usr: any; users: any[]; merchant: any[]; res: any;
  constructor(private _setup: SetupService, private _api: SigninService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllUsers();
    this.getMerchant();
    $.getScript('assets/js/plugins/footable/footable.all.min.js');
  }

  getAllUsers() {
    this._setup.getUsers().subscribe((data: any) => {
      this.users = data;
      setTimeout(() => {
        $('.footable').footable();
      }, 2000);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'User Lists', 'Unable to Get All Users'); });
  }

  editUser(data) {
    // this.usr = this.users.find(s => s.usersid === data);
    this.usr = data;
    this.edit = true; this.list = true;
  }

  delUser(dat) {
    this._setup.delUser(dat.id).subscribe((data: any) => {
      this.res = data; this.getAllUsers();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete User'); });
  }

  getMerchant() {
    this._setup.getCompanys().subscribe((data: any) => {
      this.merchant = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  reset(dat) {
    this._api.Reset(dat.email).subscribe((data: any) => {
      this.res = data;
      this._use.showMsg('success', 'User Account Reset', 'User Shld Check Email for his new password');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Reset Fail', 'Unable to Reset User');
    });
  }

  postUser() {
    if (this.usr.password === this.usr.conpassword) {
      this._setup.postUser(this.usr).subscribe((data: any) => {
        this.res = data; this.getAllUsers(); this.formReset(); this.list = false;
        this._use.showMsg('success', 'User Added', 'Added to list Successfully');
      }, (err: HttpErrorResponse) => {
        this._use.showMsg('error', 'Adding Fail', 'Unable to Add New User');
      });
    } else {
      this._use.showMsg('error', 'Adding Fail', 'User password do not match');
    }
  }

  putUser() {
    this.usr.userid = this._use.active.id; this.usr.date = new Date();
    this.usr.muserid = this._use.active.id; this.usr.mdate = new Date();
    this._setup.putUser(this.usr.id, this.usr).subscribe((data: any) => {
      this.res = data; this.getAllUsers(); this.formReset(); this.list = false;
      this._use.showMsg('success', 'User Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.edit = false;
      const mer = this._use.active.merchantid || '';
      this.usr = { id: '', fullname: '', email: '', mobile: '',
        username: '', password: '', conpassword: '', usertype: '', merchantid: mer,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null };
    }
  }

  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }
}
