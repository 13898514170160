import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-setup-company',
  templateUrl: './setup-company.component.html',
  styleUrls: ['./setup-company.component.css']
})
export class SetupCompanyComponent implements OnInit {
  @ViewChild('companyForm') public any: NgForm; id: number;
  edit: boolean; exp: any; com: any; res: any; imp: any; company: any[] = [];
  // tslint:disable-next-line:variable-name
  private _searchTitle: string; filtered: any[] = [];

  get searchTitle(): string {
    return this._searchTitle;
  }
  set searchTitle(value: string) {
    this._searchTitle = value;
    this.filtered = this.searchResult(value);
  }
  // tslint:disable-next-line:variable-name
  constructor(private api: SetupService, private _ext: ExportService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllCompany();
    this.imp = this._ext.import;
  }

  getAllCompany() {
    this.api.getCompanys().subscribe((data: any) => {
      this.company = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant Details'); });
  }

  editCom(data) {
    this.com = data;
    this.edit = true; // this.list = true;
  }

  delCom(dat) {
    this.api.delCompany(dat.companyid).subscribe((data: any) => {
      this.res = data; this.company.slice(dat);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Merchant'); });
  }

  delete(val) {
    this.api.delCompany(val.merchantid).subscribe(data=> {
      this.formReset(); document.getElementById('regis').click();
      this._use.showMsg('success', 'Company Deleted', 'Merchant Deleted Successfully');
    },(err:HttpErrorResponse)=> {
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Merchant');
    })
  }

  fileUpload(event) {
    // console.log(event);
    this.api.Upload(event.target.files[0], 'Logo').subscribe((data: any) => {
      this.com.logo = data; console.log(data);
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.company.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.status.indexOf(searchString) !== -1 || a.sender.indexOf(searchString) !== -1);
  }

  postMerchant() {
    this.api.postCompany(this.com).subscribe((data: any) => {
      this.res = data; this.getAllCompany();
      this.formReset(); document.getElementById('regis').click();
      this._use.showMsg('success', 'Merchant Added', 'New Merchant Added Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Merchant');
    });
  }

  putMerchant() {
    this.com.muserid = this._use.active.id; this.com.mdate = new Date();
    this.api.putCompany(this.com.merchantid, this.com).subscribe((data: any) => {
      this.res = data; this.getAllCompany(); this.edit = false;
      this.formReset(); document.getElementById('regis').click();
      this._use.showMsg('success', 'Merchant Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  uploadMerchant() {
    this.api.postCompany(this._ext.import).subscribe((data: any) => {
      this.res = data; this.getAllCompany();
      this.formReset(); document.getElementById('upload').click();
      this._use.showMsg('success', 'Merchant Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Merchant');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.com = { merchantid: 0, code: '', type: '', slug: '',  apikey: '', charge: 0,
        name: '', email: '', mobile: '', logo: 'logo.png', status: 'Active',
        account: '', bank: '', bankname: '', balance: 0, smsbalance: 0,
        allowsms: true, active: true, address: '', sender: '', send: 0,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this._ext.exportAsExcelFile(data, 'merchant');
      this._use.showMsg('error', 'Merchant Export Fail', 'There is no Merchant Data to Export');
    } else {
      this._use.showMsg('error', 'Merchant Export Fail', 'There is no Merchant Data to Export');
    }
  }

  read(event) {
    this.imp = this._ext.readExcel(event.target.files[0]);
    // console.log(this.imp);
    // document.getElementById('upload').click();
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, CHURCH_NAME: e.name, EXPIRY: e.expiry,
        STATUS: e.status, JOINDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

  listView() {
    this.edit = false;
  }

}
