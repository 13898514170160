import { Component, OnInit } from '@angular/core';
import { SigninService } from 'src/app/services/signin.service';

@Component({
  selector: 'app-foot',
  templateUrl: './foot.component.html',
  styleUrls: ['./foot.component.css']
})
export class FootComponent implements OnInit {
  act: any; expiry: string;
  constructor(private ser: SigninService) { }

  ngOnInit() {
    this.act = this.ser.userDetails();
  }

}
