import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ExportService } from 'src/app/services/export.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.sass']
})
export class UtilityComponent implements OnInit {
  utility: any[] = []; filtered: any[] = []; st: any; id = 0; exp: any;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ManagerService, private use: NavComponent, private ext: ExportService) { }

  ngOnInit() {
    this.getAllUtility();
  }

  getAllUtility() {
    this.api.getUtilitys().subscribe((data: any) => {
      this.utility = data; this.filtered = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Utility List');
    });
  }

  postUtility() {
    this.api.postUtility(this.st).subscribe((data: any) => {
      this.getAllUtility();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Utility Send', 'Quantity Added To current Utility Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Adding Utility Fail', 'Unable to Add New Utility');
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.utility.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1);
  }

}
