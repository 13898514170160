import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private db: AngularFirestore) { }

  post(rout, value) {
    return this.db.collection(rout).add(value);
  }

  createdata(rout: any, data: any) {
    return this.db.collection(rout).add(data);
  }

  getall(rout) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection(rout).get()
        .subscribe(snapshots => {
          resolve(snapshots);
        });
    });
  }

  getdata(rout) {
    return this.db.collection(rout).snapshotChanges();
  }

  put(rout, userKey, value) {
    // value.nameToSearch = value.name.toLowerCase();
    return this.db.collection(rout).doc(userKey).set(value);
  }

  updatedata(rout, data: any) {
    delete data.id;
    this.db.doc(rout + data.id).update(data);
  }

}
